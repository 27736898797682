/*
 * @Author: jiameng15
 * @Date: 2021-07-09 09:38:43
 * @Last Modified by: jiameng15
 * @Last Modified time: 2021-09-24 09:44:56
 * 用户相关接口
 */

import request from "@/utils/request"; // 引入request,基于axios
import queryString from "querystring"; // post方法传参转型String

export function getPage(data) {
  return request({
    url: "/mine/course/page",
    method: "get",
    params: data
  });
}