<template>
  <div class="container myCourses">
    <div class="back">
      <img src="@/assets/images/home/back@2x.png" alt @click="back()" />
      <!-- <span>我购买的课程</span> -->
    </div>
    <van-pull-refresh v-model="refreshLoading" @refresh="onRefresh()">
      <div class="theWrap">
        <van-list
          v-model="refreshLoading"
          :finished="finished"
          finished-text="没有更多了"
          :immediate-check="true"
          @load="onLoad"
          class="van-list"
        >
          <div
            class="list_item"
            v-for="item in list"
            :key="item.id"
            @click="toCourseDetails(item)"
          >
            <div class="courseImgWrap">
              <img :src="item.courseImg" alt="" class="courseImg" />
              <div class="courseImgMeng">
                <div class="joinNumWrap"></div>
                <div class="chapterCountsWrap">
                  <div class="chapterCounts">
                    共<span class="updateNum">{{ item.chapterCounts }}</span
                    >集
                  </div>
                  <div class="chapterCounts" style="margin-left: 5px;">
                    已更新<span class="updateNum">{{ item.updateNum }}</span
                    >集
                  </div>
                </div>
              </div>
            </div>
            <div class="courseNameWrap">
              <div class="courseName">
                {{ item.courseName }}
              </div>
              <div class="lecherNamesWrap">
                <div class="lecherNames">{{ item.lecherNames }}</div>
                <div class="publishDate">{{ item.publishDate }}</div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </van-pull-refresh>
    <router-view></router-view>
  </div>
</template>

<script>
import _ from "lodash";
import { getPage } from "@/api/my/myCourses";
import { Dialog, Toast } from "vant";
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    return {
      refreshLoading: false,
      list: [],
      pages: {
        current: 1,
        size: 10,
      },
      finished: false,
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    back() {
      this.$router.go(-1); //返回上一层
    },
    toCourseDetails(cat) {
      this.$router.getRoutes();
      // let childrenRoute = {
      //   path: "detail",
      //   name: "Detail",
      //   component: () => import("@/views/home/detail.vue"),
      //   meta: {
      //     title: "课程详情",
      //   },
      // };
      // this.$router.addRoute("home", childrenRoute);
      this.$router.push({
        path: `/my/myCourses/detail`,
        query: { id: cat.id },
      });
    },
    liveMinute(s) {
      console.log(s);
      let h;
      h = Math.floor(s / 60);
      //计算秒
      //算法：取得秒%60的余数，既得到秒数
      s = s % 60;
      //将变量转换为字符串
      h += "";
      s += "";
      //如果只有一位数，前面增加一个0
      h = h.length == 1 ? "0" + h : h;
      s = s.length == 1 ? "0" + s : s;
      return h + ":" + s;
    },
    // 获取列表
    async getPages() {
      let postData = {
        current: this.pages.current,
        size: this.pages.size,
      };
      const res = await getPage(postData);
      const {
        data,
        data: { records },
      } = res;
      if (this.pages.current === 1) {
        this.list = records;
      } else {
        this.list = this.list.concat(records);
      }
      this.pages.total = data.total;
      if (data.total <= this.pages.current * 10) {
        this.finished = true;
      }
      this.refreshLoading = false;
    },
    onRefresh() {
      this.pages.current = 1;
      this.getPages();
      setTimeout(() => {
        this.refreshLoading = false;
      }, 500);
    },
    onLoad() {
      this.pages.current++;
      this.finished = false;
      this.getPages();
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    // this.initWXJSSDKNoShare()
    this.getPages();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variable.scss";
.container.myCourses {
  height: 100vh !important;
  background: linear-gradient(180deg, #ffffff 0%, #f7f7f7 100%);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  .back {
    width: 100%;
    padding: 40px 20px 100px 20px;
    display: flex;
    background: #1d96f4;
    position: fixed;
    top: 0;
    left: 0;
    // height: 200px;
    z-index: 2;
    img {
      height: 45px;
      display: inline-block;
      vertical-align: middle;
    }
    span {
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 32px;
      color: #ffffff;
      line-height: 38px;
      text-align: center;
      font-style: normal;
      text-transform: none;
      display: inline-block;
      vertical-align: middle;
      margin-top: 8px;
    }
    span:last-child {
      margin: auto;
    }
  }
  .van-pull-refresh {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 120px;
    border-radius: 30px 30px 0 0;
    background: linear-gradient(180deg, #ffffff 0%, #f7f7f7 100%);
    z-index: 2;
  }
  .theWrap {
    width: 100vw;
    border-radius: 30px 30px 0 0;
    margin-top: 20px;
    padding-bottom: 100px;
    .van-list {
      margin: 30px;
      padding-top: 1px;
      .list_item {
        margin-bottom: 30px;
        width: 100%;
        border-radius: 20px;
        background: #fff;
        // box-shadow: 0px 0px 6px 4px #ebebeb;
        .courseImgWrap {
          position: relative;
          width: 100%;
          height: 380px;
          .courseImg {
            width: 100%;
            height: 100%;
            border-radius: 10px 10px 0 0;
          }

          .courseImgMeng {
            position: absolute;
            bottom: 0;
            width: -webkit-fill-available;
            background: #0000007c;
            padding: 14px 10px;
            display: flex;
            justify-content: space-between;
          }

          .joinNumWrap {
            display: flex;
            align-items: center;
          }

          .dianbo {
            width: 34px;
            height: 34px;
            margin-right: 10px;
          }

          .joinNum {
            width: 236px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 24px;
            color: #ffffff;
            line-height: 28px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .chapterCountsWrap {
            display: flex;
            align-items: center;
          }

          .chapterCounts {
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 24px;
            color: #ffffff;
            line-height: 28px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }

          .updateNum {
            color: #d5b780;
          }
        }
        .courseNameWrap {
          padding: 30px;
        }
        .courseName {
          font-family: PingFang SC, PingFang SC;
          font-weight: 600;
          font-size: 28px;
          color: #333333;
          line-height: 33px;
          text-align: left;
          font-style: normal;
          /*text-transform: none;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical; */
          margin: 0 0 16px 0;
        }
        .lecherWrap {
          display: flex;
          margin-top: 7px;
        }

        .lecherBox {
          display: flex;
          align-items: center;
          align-content: space-between;
        }

        .lecherRight {
          width: 140px;
          display: flex;
          align-items: center;
          align-content: space-between;
          justify-content: flex-end;
        }

        .lecherAva {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
        .lecherNamesWrap {
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
          .lecherNames {
            width: calc(100% - 60px);
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 24px;
            color: #666666;
            line-height: 28px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
          }

          .publishDate {
            width: 180px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 24px;
            color: #b8b8b8;
            line-height: 28px;
            text-align: right;
            font-style: normal;
            text-transform: none;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss"></style>
